import Carousel from 'bootstrap/js/src/carousel.js';

window.initializeCarousel = (carouselId) =>
{
    var myCarousel = document.getElementById(carouselId);
    var carousel = new Carousel(myCarousel, {
        ride: 'carousel',
        interval: 5000,
        slide: true
    });

}
